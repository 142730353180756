import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide18/image/imgPortadaGuia18.png"
import img1 from "@components/pageGuide/guides/guide18/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide18/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide18/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide18/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Guía para tener las mejores prácticas para la personalización de chatbots
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Los chatbots han revolucionado la forma en que las empresas se conectan y
      brindan soporte a sus clientes. Sin embargo, la personalización se ha
      convertido en el siguiente nivel de excelencia en la interacción con estos
      asistentes virtuales.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    La capacidad de adaptar los chatbots a las necesidades y preferencias
    individuales de cada usuario puede marcar una gran diferencia en la
    experiencia del cliente.
    <br /> <br />
    Con la personalización de chatbots, las empresas pueden establecer una
    conexión más fuerte con sus clientes y mejorar la eficiencia en la
    resolución de problemas, lo que resulta en una experiencia de usuario
    excepcional.
  </p>
)

const data = {
  start: {
    support: "Guía | Chatbots",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Importancia de un chatbot personalizado.",
      },
      {
        key: 2,
        text: "Pasos para crear una ruta de bienvenida.",
      },
      {
        key: 3,
        text: "Cómo personalizar un saludo.",
      },
      {
        key: 4,
        text: "Recomendación productos en base a su historial.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
